import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import AboutUs from '../views/AboutUs.vue'
import ProjectExperience from '../views/ProjectExperience.vue'
import OurPrincipals from '../views/OurPrincipals.vue'
import CompanyEvents from '../views/CompanyEvents.vue'
import IndustryShowcase from '../views/IndustryShowcase.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },  
  {
    path: '/about-us',
    name: 'about',
    component: AboutUs
  },  
  {
    path: '/project-experience',
    name: 'project-experience',
    component: ProjectExperience
  },
  {
    path: '/project-experience/:industry',
    name: 'industry-showcase',
    component: IndustryShowcase
  },  
  {
    path: '/our-principals',
    name: 'our-principals',
    component: OurPrincipals
  },  
  {
    path: '/company-events/:event',
    name: 'company-events',
    component: CompanyEvents
  },  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
})

export default router
