<template>
  <section class="aboutUs">
    <div class="aboutUs-top">
      <div class="parallelogram2">
        <h1 class="aboutTitle">A REPUTABLE COMPANY WITH A GROUP OF EXPERIENCED ENGINEERS</h1>
      </div>
    </div>
    <div class="aboutUs">
      <div class="intro">
        <h2><strong>P</strong>IONEERING ENGINEERING EXCELLENCE</h2>
        <p>Prater Engineering Associates, Inc. is a professional engineering firm specializing in the design of HVAC,
        fire protection, plumbing, electrical, and technology systems for complex facilities—corporate, data and telecommunications,
          higher education, health care, laboratories, and manufacturing plants. Our extensive and diverse experience enables us to
          offer innovative solutions to challenging problems.</p>
      </div>
      <div class="overview">
        <h2><strong>E</strong>STABLISHING CLIENT-CENTRIC ENGINEERING</h2>
        <p>Prater Engineering was founded in December 1995 in Columbus, Ohio with the goal of providing the
        highest-quality engineering services available with an emphasis on client service. Integrity and commitment form the
          cornerstone upon which our company is built, and this has enabled us to develop and maintain a reputation for 
          engineering excellence, quick response, and outstanding client service.</p>
      </div>
      <div class="principles">
        <h2><strong>F</strong>OUNDING PRINCIPLES AND REPUTATION</h2>
        <p>
          We understand that MEP systems are key components of architectural function. We feel that for a project to be successful,
          the mechanical and electrical systems must integrate seamlessly into the building architecture. Systems are designed to
          be safe, energy-efficient, cost-effective, maintainable, and quiet. The extensive experience of our staff in the design
          of all types of building systems enables us to successfully balance functional needs with architectural and budget constraints.
        </p>
      </div>
      <div class="integration">
        <h2><strong>I</strong>NTEGRATING MEP SYSTEMS WITH ARCHITECTURE</h2>
        <p>
          Our approach revolves around the understanding that MEP systems
          are not just functional necessities but integral to the architectural design. By seamlessly integrating mechanical and
            electrical systems into the building's architecture, we ensure the overall success of the project. This approach requires
            meticulous planning and expertise, which our team brings to every project.
        </p>
      </div>
      <div class="balance">
        <h2><strong>B</strong>ALANCING FUNCTION, ARCHITECTURE, AND BUDGET</h2>
        <p>
          Our team's vast experience in designing diverse building systems equips us with the ability to harmonize functional
          requirements with architectural aesthetics and financial limitations. We prioritize safety, energy efficiency,
          cost-effectiveness, maintainability, and noise reduction in our designs. This balanced approach ensures that the
          resulting systems are not only operationally excellent but also visually and economically aligned with the project's goals.
        </p>
      </div>
      <div class="sustainable">
        <h2><strong>C</strong>OMMITMENT TO SUSTAINABLE DESIGN</h2>
        <p>
          Beyond the immediate project goals, Prater Engineering acknowledges its responsibility towards sustainable design practices.
          We understand the significance of reducing environmental impact and optimizing long-term efficiency. 
          By incorporating sustainable principles into our designs, we contribute to a greener future while providing 
          cutting-edge engineering solutions to our clients.
        </p>
      </div>
      <div class="aboutUsButtons">
        <button @click="projectExperience()">
          Our Projects <i class="fa-solid fa-chevron-right"></i>
        </button>
        <button @click="ourTeam()">
          Meet our Principals <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    projectExperience() {
        this.$router.push({ name: "project-experience"})
      },
    ourTeam() {
        this.$router.push({ name: "our-principals"})
      },
  }
}
</script>

<style>
section.aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0 auto;
  overflow-x: clip;
}

div.aboutUs-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  text-align: center;
  /* background-color: rgba(80, 20, 4 , 1); */
  background-color: rgb(117, 117, 117);
}

.aboutTitle {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--third-color);
  margin: 0 auto;
  max-width: 800px;
  transform: skewX(-20deg);
}

div.aboutUs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px auto;
  max-width: 1450px;
}

div.aboutUs h2 {
  font-family: 'Vollkorn', serif;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  border-bottom: 3px solid var(--second-color);
}

div.aboutUs > div.intro, div.aboutUs > div.overview, div.aboutUs > div.balance, div.aboutUs > div.sustainable {
  margin: 40px;
  max-width: 550px;
}

div.principles, div.integration {
  margin: 40px;
  max-width: 550px;
}

div.aboutUs h2 > strong {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--second-color);
}

div.aboutUs p {
  margin-left: 10px;
  font-size: 18px;
  padding-bottom: 20px;
}

div.aboutUsButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  max-width: 900px;
}

div.aboutUs > div.aboutUsButtons > button {
  border: 3px solid var(--second-color);
  border-radius: 50px;
  padding: 10px 20px;
  margin: 0 95px;
  color: var(--first-color);
  background: none;
  font-weight: 550;
  font-size: 15px;
  cursor: pointer;
}

div.aboutUs > div.aboutUsButtons > button:hover{
  background-color: var(--second-color);
  color: var(--third-color);
  transition: .5s ease;
}

div.aboutUs > div.aboutUsButtons > button > i{
  padding: 4px 0px 0px 15px;
  margin: 0 auto;
  transform: translateX(-5px);
  transition: 1s ease;
}

div.aboutUs > div.aboutUsButtons > button:hover > i {
  transform: translateX(5px);
  color: var(--third-color);
  transition: 1s ease;
}

@media screen and (max-width: 850px) { 
  .aboutTitle {
    font-size: 33px;
    margin: 0px 10px 0px -10px; /* Used to center the text*/
  }
  
}

@media screen and (max-width: 426px) {
  div.aboutUs {
    max-width: 400px;
  }

  div.aboutUs > div.intro, div.aboutUs > div.overview, div.aboutUs > div.balance, div.aboutUs > div.sustainable {
    margin: 25px;
    max-width: 400px;
  }

  div.principles, div.integration {
    margin: 25px;
    max-width: 400px;
  }

  div.aboutUsButtons {
    margin: 10px auto;
    justify-content: space-evenly;
    max-width: 400px;
  }
  
  div.aboutUs > div.aboutUsButtons > button {
    margin: 10px 12px;
  }
}

@media screen and (max-width: 376px) {
  .aboutTitle {
    font-size: 28px;
  }
  
  div.aboutUs {
    max-width: 350px;
  }

  div.aboutUs > div.intro, div.aboutUs > div.overview, div.aboutUs > div.balance, div.aboutUs > div.sustainable {
    margin: 15px;
    max-width: 350px;
  }

  div.principles, div.integration {
    margin: 15px;
    max-width: 350px;
  }

  div.aboutUs h2 { 
    font-size: 20px;
  }

  div.aboutUs h2 > strong {
    font-size: 35px;
  }

  div.aboutUsButtons {
    margin: 10px auto;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 350px;
  }
  
  div.aboutUs > div.aboutUsButtons > button {
    margin: 20px auto;
  }
}

@media screen and (max-width: 321px) {
  .aboutTitle {
    font-size: 24px;
  }
}
</style>