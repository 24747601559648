<template>
<section>
  <div class="project">
    <div class="projectHeader">
      <img :src="project.imageSrc1" :alt="project.imageAlt1" class="projectImg1">
      <div class="projectOverview">
        <h2 class="projectTitle"><strong>{{project.title}}</strong></h2>
        <h3 class="projectSubtitle">{{project.subTitle}}</h3>
        <h4 class="projectLocation">{{project.location}}</h4>
      </div>
    </div>
    <div class="projectBody" v-show="showFullText">
      <p class="projectDescription"  v-show="showFullText"> {{ project.description }}</p>
      <img :src="project.imageSrc2" :alt="project.imageAlt2" class="projectImg2" v-show="showFullText">
    </div>
    <button @click="toggleText()" class="projectBtn">
      {{showFullText ? 'Show Less' : 'Show More'}}
      <i class="fa-solid fa-chevron-down" v-show="!showFullText"></i>
      <i class="fa-solid fa-chevron-up" v-show="showFullText"></i>
    </button>
  </div>
</section>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      showFullText: false,
    };
  },
  methods: {
    toggleText() {
      this.showFullText = !this.showFullText;
    },
  },
}
</script>

<style>
div.project {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin: 20px auto;
  max-width: 1000px;
  border-bottom: 3px solid var(--second-color);
  overflow-x: clip;
}

div.project > div.projectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin: 0;
  max-width: 100%; */
}

div.project > div.projectHeader > div.projectOverview {
  display: flex;
  flex-direction: column;
  /* flex-flow: column wrap; */
  /* align-items: center; */
  padding-left: 30px;
}

div.project > div.projectHeader > img {
  width: 500px;
  height: auto;
  padding: 10px;
  margin: 20px 20px 0px 20px;
  border: 5px solid var(--fourth-color);
  border-radius: 10px;
}


div.project > div.projectBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

div.project > div.projectBody > img {
  width: 350px;
  height: auto;
  padding: 10px;
  margin: 20px;
  border: 5px solid var(--second-color);
  border-radius: 10px;
}

div.project div.projectOverview h2 > strong {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--second-color);
}

div.project div.projectOverview h3 {
  font-family: 'Vollkorn', serif;
  font-weight: 600;
  font-size: 30px;
}

div.project div.projectOverview h4 {
  font-family: 'Vollkorn', serif;
  font-weight: 600;
  font-size: 20px;
}

p.projectDescription {
  max-width: 40%;
  font-size: 18px;
  margin: 25px;
}

div.project > button.projectBtn {
  padding: 20px 10px;
  border: none;
  background-color: white;
  color: var(--first-color);
  font-size: 15px;
  font-weight: 600;
  margin-right: 40px;
}

div.project > button.projectBtn:hover {
  color: var(--second-color);
  cursor: pointer;
  transition: .5s ease;
}

div.project > button.projectBtn > i {
  margin: 3px 3px;
  color: var(--second-color);
}

@media screen and (max-width: 850px) {
  div.project {
    max-width: 700px;
  }

  div.project > div.projectHeader > div.projectOverview {
    padding-left: 20px;
  }

  div.project > div.projectHeader > img {
    width: 350px;
  }

  div.project div.projectOverview h2 > strong {
    font-size: 33px;
  }

  div.project div.projectOverview h3 {
    font-size: 25px;
  }

  div.project div.projectOverview h4 {
    font-size: 20px;
  }

}

@media screen and (max-width: 426px) {
  div.project {
    max-width: 400px;
  }

  div.project > div.projectHeader {
    flex-direction: column;
  }

  div.project > div.projectHeader > div.projectOverview {
    margin-top: 20px;
  }
  div.project > div.projectBody {
    flex-direction: column;
  }

  p.projectDescription {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 376px) {
  div.project {
    max-width: 325px;
  }

  div.project > div.projectHeader > img, div.project > div.projectBody > img {
    width: 325px;
  }

  div.project div.projectOverview h2 > strong {
    font-size: 33px;
  }

  div.project div.projectOverview h3 {
    font-size: 25px;
  }

  div.project div.projectOverview h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 321px) {
  div.project {
    max-width: 280px;
  }
  
  div.project > div.projectHeader > div.projectOverview {
    padding-left: 10px;
  }
  div.project > div.projectHeader > img, div.project > div.projectBody > img {
    width: 280px;
  }

  div.project div.projectOverview h2 > strong {
    font-size: 30px;
  }

  div.project div.projectOverview h3 {
    font-size: 25px;
  }

  div.project div.projectOverview h4 {
    font-size: 20px;
  }
}
</style>