<template>
  <section class="industryShowcase">
    <!-- This view will be used to populate the specific industry's project examples. -->
    <!-- User will select and industry from the ProjectExperience view, will then be sent to this view that given an industry will pull the examples from the store 
            and v-for that info into the ProjectShowcase.vue Component -->
    <div class="industryShowcase-top">
      <div class="parallelogram3">
        <h1 class="industryTitle">{{ this.industryTitle }}</h1>
      </div>
    </div>
    <div class="projectShowcase">
      <button class="backToIndustries" @click="backToIndustries()"><i class="fa-solid fa-chevron-left"></i>Back To Industries</button>
      <project-showcase v-for="project in projects" :key="project.id" :project="project" />
    </div>
  </section>
</template>

<script>
import ProjectShowcase from '../components/ProjectShowcase.vue';

export default {
  data() {
    return {
      industryTitle: '',
      projects: [],
    };
  },
  components: { ProjectShowcase },
  methods: {
    setTitle() {
      if (this.$route.params.industry == 'ArcFlashHazardAnalysis'){
        return this.industryTitle = 'Arc Flash Hazard Analysis';
      }
      else if (this.$route.params.industry == 'CorporateOffices') {
        return this.industryTitle = 'Corporate Offices';
      }
      else if (this.$route.params.industry == 'Correctional') {
        return this.industryTitle = 'Correctional';
      }
      else if (this.$route.params.industry == 'DataCenter') {
        return this.industryTitle = 'Data Center';
      }
      else if (this.$route.params.industry == 'HealthCare') {
        return this.industryTitle = 'Healthcare';
      }
      else if (this.$route.params.industry == 'HigherEducation'){
        return this.industryTitle = 'Higher Education';
      }
      else if (this.$route.params.industry == 'Laboratories') {
        return this.industryTitle = 'Laboratories';
      }
      else if (this.$route.params.industry == 'MixedUse') {
        return this.industryTitle = 'Mixed-Use';
      }
      else {
        return this.industryTitle = 'Recreation';
      }
    },
    setProjectArray() {
      if (this.$route.params.industry == 'ArcFlashHazardAnalysis'){
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Arc Flash Hazard Analysis'));
        
      }
      else if (this.$route.params.industry == 'CorporateOffices') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Corporate Offices'));
      }
      else if (this.$route.params.industry == 'Correctional') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Correctional'));
      }
      else if (this.$route.params.industry == 'DataCenter') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Data Center'));
      }
      else if (this.$route.params.industry == 'HealthCare') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Health Care'));
      }
      else if (this.$route.params.industry == 'HigherEducation'){
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Higher Education'));
      }
      else if (this.$route.params.industry == 'Laboratories') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Laboratory'));
      }
      else if (this.$route.params.industry == 'MixedUse') {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Mixed Use'));
      }
      else {
        return this.projects = this.$store.state.projects.filter(project => project.industry.includes('Recreation'));
      }
    },
    backToIndustries() {
      this.$router.push({name: "project-experience"});
    },
  },
  created() {
      this.setTitle();
      this.setProjectArray();
  },
}
</script>

<style>
section.industryShowcase {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0 auto;
}

div.industryShowcase-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  text-align: center;
  background-color: rgba(80, 20, 4 , 1);
  /* background-color: rgb(117, 117, 117); */
}

.industryTitle {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--third-color);
  margin: 0 auto;
  max-width: 800px;
  transform: skewX(-20deg);
}

section.industryShowcase button.backToIndustries {
  border: 3px solid var(--second-color);
  border-radius: 50px;
  padding: 10px 20px;
  margin-left: 0;
  margin-right: auto;
  color: var(--first-color);
  background: none;
  font-weight: 550;
  font-size: 15px;
  cursor: pointer;
}

section.industryShowcase button.backToIndustries:hover{
    background-color: var(--second-color);
    color: var(--third-color);
    transition: .5s ease;
}

section.industryShowcase button.backToIndustries > i{
  padding: 4px 0px;
  margin-right: 15px;
  transform: translateX(5px);
  transition: 1s ease;
}

section.industryShowcase button.backToIndustries:hover > i {
  transform: translateX(-5px);
  color: var(--third-color);
  transition: 1s ease;
}

div.projectShowcase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 1450px;
}

@media screen and (max-width: 1024px) {

  .industryTitle {
    font-size: 33px;
  }
  
  div.projectShowcase {
    max-width: 900px;
  }
}

@media screen and (max-width: 850px) {

  .industryTitle {
    font-size: 33px;
  }
  
  div.projectShowcase {
    max-width: 700px;
  }
}

@media screen and (max-width: 376px) {
  .industryTitle {
    font-size: 28px;
  }
  div.projectShowcase {
    max-width: 350px;
  }
}

@media screen and (max-width: 321px) {
  .industryTitle {
    font-size: 24px;
  }
  div.projectShowcase {
    max-width: 300px;
  }
}
</style>