<template>
  <div class="imageCounter">{{currentIndex + 1}} / {{total}}</div>
</template>

<script>
export default {
    props: {
        currentIndex: Number,
        total: Number,
    }
}
</script>

<style>
.imageCounter {
    width: 60px;
    height: auto;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 2;
    top: 82.5%;
    right: 9.5%;
}
@media screen and (max-width: 850px) {
    .imageCounter {
        top: 87%;
        right: 3%;
    }
}

@media screen and (max-width: 426px) {
    .imageCounter {
        top: 77%;
        right: 5.5%;
    }
}

@media screen and (max-width: 376px) {
    .imageCounter {
        top: 74%;
        right: 6%;
    }
}

@media screen and (max-width: 321px) {
    .imageCounter {
        top: 77%;
        right: 7%;
    }
}
</style>