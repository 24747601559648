<template>
  <section class="home">
    <section class="what">
      <div class="hero">
        <img src="..\images\MiscPhotos\JonesDayNE1.jpg" alt="">
        <div class="img-overlay">
          <h1>CONSULTING ENGINEERING</h1>
          <h3>Mechanical, Electrical, Plumbing, Fire Protection, Commissioning, LEED, and Technology Systems</h3>
        </div>
      </div>
    </section>
    <section class="how">
      <h2>Industry Showcase</h2>
      <div class="industries">
        <div class="corporateOffices" >
          <div class="img-container" @click="industryShowcase('CorporateOffices')">
          <h3 id="corporateOffices">Corporate Offices</h3>
          </div>
          <img src="..\images\ProjectExperience\CorporateOffice\Nationwide10West2sm.jpg" alt="">
        </div>
        <div class="healthCare" >
          <div class="img-container" @click="industryShowcase('HealthCare')">
          <h3 id="healthCare">Healthcare</h3>
          </div>
          <img src="..\images\ProjectExperience\HealthCare\OSUMorehouse.jpg" alt="">
        </div>
        <div class="higherEd" >
          <div class="img-container" @click="industryShowcase('HigherEducation')">
          <h3 id="higherEd">Higher Education</h3>
          </div>
          <img src="..\images\ProjectExperience\HigherEducation\WithrowHall.jpeg" alt="">
        </div>
      </div>
      <button class="projectExp" @click="projectExperience()">
        See All
        <i class="fa-solid fa-chevron-right"></i>
      </button>
      <div class="awardsSection">
        <h2>Awards</h2>
        <div class="awards">
            <div class="awardImgs">
              <img src="..\images\MiscPhotos\aep-ohio-power.jpg" alt="">
              <img src="..\images\MiscPhotos\DukeTradeAlly.png" alt="">
            </div>
            <ul class="awardsList">
              <li>
                2016 AEP Ohio Energy Efficiency Top New Construction Performance Award
              </li>
              <li>
                2017 New Construction Excellence (AEP Ohio Business Incentive Program)
              </li>
              <li>
                2018 Trade Ally Award - For commitment to energy (by Duke Energy)
              </li>
              <li>
                2018 Engineering Firm Excellence New Construction (AEP Ohio Business Incentive Program)
              </li>
              <li>
                2019 Engineering Firm Excellence New Construction (AEP Ohio Business Incentive Program)
              </li>
            </ul>
        </div>
      </div>
    </section>
    <section class="who">
      <div>
        <h2 class="trust">Trust Our Engineering Experts <br> to Build Excellence Into Every Design</h2>
        <p class="desc">
          Prater Engineering Associates, Inc. was founded in December 1995 and is located in Dublin, Ohio. 
          We are a professional engineering firm specializing in the design of HVAC, fire protection, plumbing, electrical, and 
          technology systems for complex facilities including higher education, data centers, telecommunications centers, corporate, health care, and laboratories. 
          Integrity and commitment form the cornerstone upon which our company was built, and this has enabled us to develop and maintain a reputation for engineering
          excellence, quick response, and outstanding client service.
        </p>
        <button @click="about()">
          Learn More <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
      <img src="..\images\MiscPhotos\skyscrapers.jpg" alt="">
    </section>
    <section class="mem">
      <img src="..\images\MiscPhotos\kevin.jpg" alt="">
      <div class="memPara">
        <h2 class="kTitle">Prater Engineering Announces <br> the Passing of Vice President Kevin O'Mara</h2>
        <p class="kBody">Prater Engineering Associates is sad to announce the passing of our Vice President, Kevin O'Mara.
          Kevin was one of the founding partners at Prater Engineering, and served as the Mechanical Department Manager
            where his passion for engineering and helping others thrived. Kevin provided great knowledge and leadership
            to the company, and his door was always open to anyone in need. His willingness to share his gifts for the
            benefit of his employees and clients and to influence positive outcomes can be seen throughout many places
            in the Greater Columbus area including Nationwide Arena District, Grandview Yard, Children's Hospital, 
            and the Columbus Zoo and Aquarium. Kevin was truly one of a kind and his sense of humor and caring personality
            will be greatly missed, but not forgotten.
        </p>
        <p class="kBody2">Kevin is survived by his parents, Brian and Sally O'Mara,
            wife Teresa and his children Kirby O'Mara (wife Allie, and granddaughter Lainey) Kaitlyn Baker (husband Shelby)
            and Christopher O'Mara. He is also survived by sister Kimberly Hillman (husband Fred) and brother Brian O’Mara
            (wife Carrie) in addition to his many nieces and nephews.</p>
      </div>
    </section>
  </section>
</template>

<script>
export default {
    methods: {
      about() {
        this.$router.push({ name: "about"})
      },
      projectExperience() {
        this.$router.push({ name: "project-experience"})
      },
      industryShowcase(industry) {
        this.$router.push({ name: "industry-showcase", params: {industry: industry}})
      }
    },
}
</script>

<style scoped>
section.home {
  display: grid;
  grid-template-areas:
    "what what"
    "how how"
    "who who"
    "mem mem";
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  overflow-x: clip;
}
section.what {
  grid-area: what;
  max-width: 1250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  width: 80%;
}

section.what > div.hero {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

section.what  img {
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 10px;
  border: 5px solid var(--fourth-color);
  border-radius: 10px;
}

section.what  div.img-overlay{
  position: absolute;
  width: 520px;
  left: 2%;
  top: 30%;
  height: auto;
  background: content-box rgba(163, 40, 7, 0.8);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  color: var(--third-color);
}

div.img-overlay > h1 {
  font-family: 'Vollkorn', serif;
  font-size: 70px;
  padding: 10px 0 0 15px;
}

div.img-overlay > h3 {
  font-size: 20px;
  padding: 0 10px 18px 90px;
}

section.how {
  grid-area: how;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 1250px; */
  background-color: var(--second-color);
  color: var(--third-color);
  padding: 50px;
  width: 100%;
}

section.how  h2 {
  font-family: 'Vollkorn', serif;
  padding-bottom: 30px;
  font-weight: 700;
}

div.industries {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  padding-top: 20px;
  padding-bottom: 50px;
  width: auto;
}

div.industries > div {
  padding-left: 80px;
  padding-right: 80px;
}

div.industries  img {
  width: 350px;
  height: 300px;
  border-radius: 40px;
}

div.industries div.img-container {
  position: absolute;
  top: 5.35%;
  background-color: rgba(73, 73, 73, 0.5);
  z-index: 2;
  font-size: 20px;
  height: 300px;
  width: 350px;
  border-radius: 40px;
  transition: all .5s ease;
  padding-left: 80px;
  padding-right: 80px;
}

div.img-container > h3 {
  position: absolute;
  top: 85%;
  transition: all .8s ease;
}

div.industries div.img-container:hover {
  background-color: rgba(48, 48, 48, 0.7);
  cursor: pointer;
  transition: all .5s ease;
}

div.img-container:hover > h3 {
  top: 45%;
  transition: all .8s ease;
}

div.img-container > h3#higherEd {
  left: 26%;
}

div.img-container > h3#healthCare {
  left: 34%;
}

div.img-container > h3#corporateOffices {
  left: 27%;
}

section.how > button.projectExp {
  border: 3px solid var(--third-color);
  background-color: rgba(163, 40, 7, 1);
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: var(--third-color);
}

section.how > button.projectExp:hover{
    background-color: var(--third-color);
    color: var(--first-color);
    transition: .5s ease;
}

section.how > button.projectExp > i{
  padding: 4px 0px 0px 15px;
  transform: translateX(-5px);
  transition: 1s ease;
}

section.how > button.projectExp:hover > i {
  transform: translateX(5px);
  color: var(--second-color);
  transition: 1s ease;
}

div.awardsSection{
    border-top: 3px solid white;
    margin: 25px;
    display: flex;
    flex-direction: column;
}

div.awardsSection > h2 {
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 20px 50px;
}

section.how  div.awards {
  display: grid;
  grid-template-areas: "imgs awards";
  grid-template-columns: 1fr 1fr;
  column-gap: 200px;
  margin: 20px;
}

div.awards > ul.awardsList {
  grid-area: awards;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  /* padding-right: 30px; */
}

div.awards img {
  height: 150px;
  width: auto;
  background-color: var(--third-color);
  border-radius: 40px;
  /* margin: 10px; */
  margin: auto 50px;
}

div.awards > div.awardImgs {
  grid-area: imgs;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
}

div.awards > ul.awardsList li {
  padding: 5px;
  margin-left: 0;
  margin-right: auto;
  font-weight: 600;
}

section.who {
  grid-area: who;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px 50px;
  padding: 50px 20px 25px 20px;
}

section.who > div {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 50px;
  max-width: 30%;
}

section.who > img {
  height: 600px;
  width: auto;
  margin-right: 50px;
  margin-left: 30px;
}

section.who > div > h2 {
  font-family: 'Vollkorn', serif;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  border-bottom: 3px solid var(--second-color);
}

section.who > div > p {
  margin-left: 10px;
  font-size: 18px;
  padding-left: 10px;
  padding-bottom: 20px;
}

section.who button {
  border: 3px solid var(--second-color);
  border-radius: 50px;
  padding: 10px 20px;
  margin: 20px auto;
  color: var(--first-color);
  background: none;
  font-weight: 550;
  font-size: 15px;
  cursor: pointer;
}

section.who button:hover{
    background-color: var(--second-color);
    color: var(--third-color);
    transition: .5s ease;
}

section.who button > i{
  padding: 4px 0px 0px 15px;
  margin-right: 0;
  transform: translateX(-5px);
  transition: 1s ease;
}

section.who button:hover > i {
  transform: translateX(5px);
  color: var(--third-color);
  transition: 1s ease;
}

section.mem {
  grid-area: mem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px 50px;
  padding: 25px 20px 50px 20px;
}

section.mem > div {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 50px;
  max-width: 35%;
}

section.mem > img {
  height: 600px;
  width: auto;
  margin-right: 30px;
  margin-left: 50px;
}

section.mem > div > h2 {
  font-family: 'Vollkorn', serif;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  border-bottom: 3px solid var(--second-color);
}

section.mem > div > p {
  margin-left: 10px;
  font-size: 18px;
  padding-bottom: 20px;
  padding-left: 10px;
}

@media screen and (max-width: 1025px) {
  
  div.industries {
    width: 800px;
  }

  div.industries  img, div.industries div.img-container {
    width: 280px;
    height: 250px;
  }

  div.industries > div {
    padding: 30px 30px 0px 30px;
  }
  
  div.industries > div.corporateOffices > div.img-container, div.industries > div.healthCare > div.img-container, div.industries > div.higherEd > div.img-container {
    top: 14%;
  }

  div.img-container > h3#higherEd {
    left: 22%;
  }

  div.img-container > h3#healthCare {
    left: 32%;
  }

  div.img-container > h3#corporateOffices {
    left: 21%;
  }

  section.how > div.awardsSection > div.awards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div.awards > ul.awardsList {
    padding: 80px 30px 0px 70px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
  }

  div.awards > ul.awardsList li {
    padding: 20px;
    margin-left: 0;
    margin-right: auto;
    font-weight: 600;
  }

  section.who > div, section.mem > div {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-left: 50px;
    max-width: 50%;
  }
  section.who > img, section.mem > img{
    height: 400px;
    width: auto;
    margin-right: 50px;
    margin-left: 30px;
  }

}

@media screen and (max-width: 850px) {
  section.what {
    max-width: 700px;
    width: auto;
  }

  section.what > div.hero  {
    width: 700px;
  }

  section.what img {
    width: 700px;
  }

  section.what div.img-overlay {
    width: 425px;
    height: 200px;
    left: 3%;
    top: 46%;
  }

  div.img-overlay > h1 {
    font-size: 45px;
  }

  div.img-overlay > h3 {
    font-size: 18px;
    padding: 0px 10px 10px 30px;
  }

  div.industries {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 700px; */
  }

  div.industries > div {
    padding: 30px;
  }

  div.industries > div:last-of-type {
    padding-bottom: 10px;
  }

  div.industries img, div.industries div.img-container {
    width: 350px;
    height: 300px;
  }

  div.industries > div.corporateOffices > div.img-container {
    top: 4.4%;
  }
  
  div.industries > div.healthCare > div.img-container {
    top: 36.3%;
  }
  
  div.industries > div.higherEd > div.img-container {
    top: 68.2%;
  }

  div.img-container > h3#higherEd {
    left: 26%;
  }

  div.img-container > h3#healthCare {
    left: 35%;
  }

  div.img-container > h3#corporateOffices {
    left: 25%;
  }

  section.how > button.projectExp {
    margin-top: 0px;
  }

  div.awards > ul.awardsList {
    /* align-items: center; */
    padding-top: 50px;
    padding: 50px 30px 0px 70px;
  }

  section.who {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 0px;
  }

  section.who > div {
    order:2;
    max-width: 100%;
    padding-top: 40px;
    margin: auto;
  }

  section.who > img {
    order:1;
    padding: auto;
    margin: auto;
  }

  section.who button {
    margin-bottom: 0px;
  }

  section.mem {
    flex-direction: column;
    padding-top: 0px;
  }
  
  section.mem > div {
    max-width: 100%;
    padding-top: 0px;
    margin: auto;
    order: 1;
  }

  section.mem > img {
    padding: auto;
    padding-top: 30px;
    margin: auto;
    order: 2;
  }
}

@media screen and (max-width: 426px) {
  section.what {
    max-width: 100vw;
    width: auto;
    padding: 0px;
    margin: 0px;
    overflow-x: clip;
  }

  section.what > div.hero  {
    width: 100vw;
    padding: 0px;
    margin: 0px;
  }

  section.what img {
    border: none;
    width: 200%;
    height: auto;
    padding: 0px 0px 15px 0px;
    margin: 0px;
  }

  section.what div.img-overlay {
    width: 92vw;
    height: auto;
    left: 2%;
    top: 55%;
    background: content-box rgba(163, 40, 7, 0.8);
    color: var(--third-color);
  }

  div.img-overlay > h1 {
    font-size: 35px;
  }

  div.img-overlay > h3 { 
    font-size: 20px;
  }

  div.awardsSection {
    margin-bottom: 0px;
  }

  section.how div.awards {
    margin-bottom: 0px;
  }

  div.awards img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  div.awards > div.awardImgs {
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    margin-left: 0px;
  }

  div.awards > ul.awardsList {
    padding: 0px 10px;
    margin: 10px 10px 0px 10px;
    font-size: 20px;
  }

  section.who, section.mem {
    /* padding-top: 20px; */
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }

  section.who > img, section.mem > img {
    width: 90vw;
    height: auto;
  }
}

@media screen and (max-width: 376px) {
  section.what div.img-overlay {
    top: 50%;
  }

  div.industries  img, div.industries div.img-container {
    width: 85vw;
    height: 80vw;
  }

   div.industries > div.corporateOffices > div.img-container {
    top: 4.35%;
  }
  
  div.industries > div.healthCare > div.img-container {
    top: 36.25%;
  }
  
  div.industries > div.higherEd > div.img-container {
    top: 68.15%;
  }

  div.img-container > h3#corporateOffices {
    left: 22.5%;
  }

  div.img-container > h3#healthCare {
    left: 34%;
  }

  div.img-container > h3#higherEd {
    left: 25%;
  }
}

@media screen and (max-width: 321px) {
  section.what div.img-overlay {
    top: 48%;
  }

  div.img-overlay > h1 {
    font-size: 30px;
  }

  div.img-overlay > h3 { 
    font-size: 18px;
  }

  div.industries > div.corporateOffices > div.img-container {
    top: 4.9%;
  }
  
  div.industries > div.healthCare > div.img-container {
    top: 36.6%;
  }
  
  div.industries > div.higherEd > div.img-container {
    top: 68.3%;
  }

  div.img-container > h3#corporateOffices {
    left: 19%;
  }

  div.img-container > h3#healthCare {
    left: 32%;
  }

  div.img-container > h3#higherEd {
    left: 21%;
  }

  div.awards > ul.awardsList {
    font-size: 18px;
  }

}
</style>