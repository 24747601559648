<template>
  <section class="companyEvents">
    <div class="companyEvent-top">
      <div class="parallelogram1">
          <!-- Determining title this way instead of how it is done for the industry showcase because anchor tags are prevented from reloading the page in the navbar -->
          <h1 class="eventTitle" v-if="this.$route.params.event == 'GolfOuting2022'">2022 RYAN C. TREECE MEMORIAL GOLF OUTING</h1>
          <h1 class="eventTitle" v-else-if="this.$route.params.event == 'GolfOuting2021'">2021 RYAN C. TREECE MEMORIAL GOLF OUTING</h1>
          <h1 class="eventTitle" v-else >Company Events</h1>
      </div>
    </div>
    <event-component/>
  </section>
</template>

<script>
import EventComponent from '../components/EventComponent.vue';

export default {
  data() {
    return {

    };
  },
  components: {
    EventComponent
  },
  methods: {

  },
}
</script>

<style>
section.companyEvents {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0 auto;
  overflow-x: clip;
}

div.companyEvent-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: rgba(80, 20, 4 , 1);
}

.eventTitle {
  font-family: 'Vollkorn', serif;
  font-size:40px;
  font-weight: 700;
  color: var(--third-color);
  margin: 0 auto;
  max-width: 800px;
  transform: skewX(20deg);
}

@media screen and (max-width: 850px) {
  .eventTitle {
    font-size: 33px;
  }
}

@media screen and (max-width: 426px) {
  .eventTitle {
    font-size: 28px;
  };
}
@media screen and (max-width: 376px) {
  .eventTitle {
    font-size: 27px;
  }
}

@media screen and (max-width: 321px) {
  .eventTitle {
    font-size: 23px;
  }
}
</style>