<template>
  <section class="ourTeam">
    <div class="ourTeam-top">
      <div class="parallelogram2">
        <h1 class="teamTitle">MEET OUR PRINCIPALS</h1>
      </div>
    </div>
    <div class="ourTeam">
      <team-member
      v-for="member in teamMembers"
      :key="member.id"
      :name="member.name"
      :title="member.title"
      :description="member.description"
      :imageSrc="member.imageSrc"
      :imageAlt="member.imageAlt"
      :imagePosition="member.id % 2 === 0 ? 'beforeTitle' : 'afterParagraph'"
      />
    </div>
  </section>
</template>

<script>
import TeamMember from '../components/TeamMember.vue'
export default {
  components: { 
    TeamMember 
  },
  computed: {
    teamMembers() {
      return this.$store.state.teamMembers;
    }
  }
}
</script>

<style>
section.ourTeam {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0 auto;
  overflow-x: clip;
}

div.ourTeam-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: rgb(117, 117, 117);
}

.teamTitle {
  font-family: 'Vollkorn', serif;
  font-size:40px;
  font-weight: 700;
  color: var(--third-color);
  margin: 0 auto;
  max-width: 800px;
  transform: skewX(-20deg);
}

div.ourTeam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 1450px;
}

@media screen and (max-width: 850px) { 
  .teamTitle {
    font-size: 33px;
  }
} 

@media screen and (max-width: 321px) {
  .teamTitle {
    font-size: 28px;
  }
}
</style>