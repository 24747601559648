<template>
  <section class="imageGallery">
    <div class="galleryTop">
      <button class="loadLessBtn" @click.prevent="loadLessImages"><i class="fa-solid fa-angles-left fa-2xl"></i></button>
      <button class="prev-Btn" @click.prevent="prevBtn"><i class="fa-solid fa-chevron-left fa-2xl"></i></button>
      <div class="selectedImage">
        <img :src="currentImage.src" :alt="currentImage.alt" />
        <image-counter :currentIndex="currentImageIndex" :total="images.length" />
      </div>
      <button class="next-Btn" @click.prevent="nextBtn"><i class="fa-solid fa-chevron-right fa-2xl"></i></button>
      <button class="loadMoreBtn" @click.prevent="loadMoreImages"><i class="fa-solid fa-angles-right fa-2xl"></i></button>
    </div>
    <div class="mobileGallery">
      <div class="selectedImage">
        <img :src="currentImage.src" :alt="currentImage.alt" />
        <image-counter :currentIndex="currentImageIndex" :total="images.length" />
      </div>
      <div class="galleryBtns">
        <button class="loadLessBtn" @click.prevent="loadLessImages"><i class="fa-solid fa-angles-left fa-2xl"></i></button>
        <button class="prev-Btn" @click.prevent="prevBtn"><i class="fa-solid fa-chevron-left fa-2xl"></i></button>
        <button class="next-Btn" @click.prevent="nextBtn"><i class="fa-solid fa-chevron-right fa-2xl"></i></button>
        <button class="loadMoreBtn" @click.prevent="loadMoreImages"><i class="fa-solid fa-angles-right fa-2xl"></i></button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ImageCounter from './ImageCounter.vue';

export default {
  components: {
    ImageCounter,
  },
    data() {
        return {
            currentImageIndex: 0,
            imagesPerPage: 10,
        };
    },
    computed: {
         ...mapState(['images']),
         currentImage() {
          return this.images[this.currentImageIndex];
         },
    },
    methods: {
        prevBtn() {
            if (this.currentImageIndex > 0) {
                this.currentImageIndex--;
            }
        },
        nextBtn() {
            if (this.currentImageIndex < this.images.length - 1) {
                this.currentImageIndex++;
            }
        },
        // selectImage(index) {
        //   // Only update the currentImageIndex without changing the visible range
        //   this.currentImageIndex = index;
        // },
        loadMoreImages() {
          // Increment the currentImageIndex by the imagesPerPage amount
          this.currentImageIndex += this.imagesPerPage;

          // Ensure the currentImageIndex doesn't exceed the total number of images
          if (this.currentImageIndex >= this.images.length) {
            this.currentImageIndex = this.images.length - 1;
          }
        },
        loadLessImages() {
          // Increment the currentImageIndex by the imagesPerPage amount
          this.currentImageIndex -= this.imagesPerPage;

          // Ensure the currentImageIndex doesn't go below 0
          if (this.currentImageIndex < 0) {
            this.currentImageIndex = 0;
          }
        },
        ...mapActions(['importImages']),
        importImagesBasedOnRoute() {
            const event = this.$route.params.event;
            try {
                this.importImages(event); // Trigger the action with the route parameter.
            } catch (error) {
                //Handle any error thrown from the Vuex Action
                console.error(error);
            }
         },
    },
     created() {
         this.importImagesBasedOnRoute(); // Trigger the action to import images when the component is created.
    },
}
</script>

<style scoped>
.imageGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1450px;
  margin: 40px;
  overflow-x: clip;
}

.galleryTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageGallery button, .galleryBtns button{
  background: none;
  border: none;
  cursor: pointer;
  margin: 20px;
}

.imageGallery i {
  color: var(--first-color);
}

.imageGallery button:hover i {
  color: var(--second-color);
}

div.selectedImage {
  position: relative;
}

.selectedImage img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  padding: 30px;
  margin: 30px 30px 15px 30px;
  border: 5px solid var(--second-color);
  border-radius: 10px;
  background-color: rgba(109, 109, 109, 0.2)
}

.mobileGallery {
  display: none;
}

@media screen and (max-width: 1000px) {
  div.selectedImage {
    width: 100%;
    margin-bottom: 0px;
  }

  .imageGallery button {
    margin-top: 0px;
  }

  .imageGallery button.prev-Btn {
    margin-right: 0px;
  }

  .imageGallery button.next-Btn {
    margin-left: 50px;
  }

  .imageGallery button.loadMoreBtn {
    margin-left: 5px;
    margin-right: 0px;
  }

  .imageGallery button.loadLessBtn {
    margin-right: 5px;
    margin-left: 0px;
  }
  .galleryTop {
    display: none;
  }
  .mobileGallery {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .selectedImage img {
    margin: auto auto 0px auto;
    padding: 10px;
  }
  .galleryBtns {
    margin: 10px auto 0px auto;
  }
  .galleryBtns button {
    margin-bottom: 0px;
    padding: 10px;
  }
}

@media screen and (max-width: 321px) {
  .galleryBtns {
    margin: 10px;
  }

  .selectedImage img {
    max-width: 300px;
    width: 300px;
    height: auto;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .galleryBtns button {
    padding: 4.1px;
  }
  
}
</style>