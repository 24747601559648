<template>
<header class="gradient-background">
  <nav>
    <button class="navbar logoBtn" @click="handleNavButtonClick('home')">
    <img class="logo" src="..\images\Logo\PraterLogoNoAddress.jpg" alt="">
    </button>
    <button class="navbar" @click="handleNavButtonClick('about')" :class="{'activePath': $route.path === '/about-us'}">ABOUT US</button>
    <button class="navbar" @click="handleNavButtonClick('project-experience')" :class="{'activePath': $route.path.includes('project')}">PROJECT EXPERIENCE</button>
    <button class="navbar" @click="handleNavButtonClick('our-principals')" :class="{'activePath': $route.path === '/our-principals'}">OUR PRINCIPALS</button>
    <div class="dropdown">
        <button class="navbar dropbtn" :class="{'activePath': $route.path.includes('/company-events')}">COMPANY EVENTS <i class="fa-solid fa-chevron-down"></i></button>
        <div class="dropContent">
            <a href="" class="golf22" @click="pushToCompanyEvent('GolfOuting2022')" :class="{'activePath': $route.path.includes('2022')}">Golf Outing 2022</a>
            <a href="" class="golf21" @click="pushToCompanyEvent('GolfOuting2021')" :class="{'activePath': $route.path.includes('2021')}">Gold Outing 2021</a>
        </div>
    </div>
    <button class="navbar" id="contact" @click="scrollToFooter()">CONTACT US</button>
    <div class="mobileDropdown">
        <button class="hamburger">
            <i class="fa-solid fa-bars fa-2xl" id="mobileHeaderMenu" @click="toggleIsActive()" v-show="isActive"></i>
            <i class="fa-solid fa-xmark fa-2xl" id="mobileHeaderMenu" @click="toggleIsActive()" v-show="!isActive"></i>
        </button>
        <div class="hamburgerContent" v-show="!isActive">
            <button class="navbar" @click="[handleNavButtonClick('home'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path === '/'}">HOME</button>
            <button class="navbar" @click="[handleNavButtonClick('about'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path === '/about-us'}">ABOUT US</button>
            <button class="navbar" @click="[handleNavButtonClick('project-experience'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path.includes('project')}">PROJECT EXPERIENCE</button>
            <button class="navbar" @click="[handleNavButtonClick('our-principals'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path === '/our-principals'}">OUR PRINCIPALS</button>
            <button class="navbar" @click="toggleShowEvents()" :class="{'activePath': $route.path.includes('/company-events')}">COMPANY EVENTS</button>
            <div class="nestedHamburgerContent" v-show="showEvents">
                <a href="" class="golf22" @click="[pushToCompanyEvent('GolfOuting2022'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path.includes('2022')}">Golf Outing 2022</a>
                <a href="" class="golf21" @click="[pushToCompanyEvent('GolfOuting2021'), toggleIsActive(), toggleShowEvents('false')]" :class="{'activePath': $route.path.includes('2021')}">Golf Outing 2021</a>
            </div>
            <button class="navbar" id="contact2" @click="[scrollToFooter(), toggleIsActive(), toggleShowEvents('false')]">CONTACT US</button>
        </div>
    </div>
  </nav>
</header>
</template>

<script>
export default {
    data() {
        return {
            isActive: true,
            showEvents: false,
        }
    },
    methods: {
        handleNavButtonClick(page) {
            // Check to see if the button clicked is for the current route
            if (this.$route.name === page) {
                // If so, scroll to the top of the page, smoothly
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
            else {
                // If not the current route, go to the corresponding route
                this.$router.push({ name: page});
            }
        },
        scrollToFooter() {
            const footerElement = this.$root.$refs.footerForm.$el;
                footerElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
        },
        pushToCompanyEvent(event) {
            this.$router.push({ name: "company-events", params: {event: event}})
        },
        toggleIsActive() {
            this.isActive = !this.isActive;
            return this.isActive;
        },
        toggleShowEvents(bool) {
            if (bool == 'false') {
                this.showEvents = false;
                return this.showEvents;
                }
            else {
                this.showEvents = !this.showEvents;
                return this.showEvents;
            }

        }
    },
}
</script>

<style>
header {
    position: sticky;
    /* position: fixed; */
    top: 0;
    z-index: 100;
    /* width: 100vw; */
    padding-bottom: 6px;
    background-color: white;
    border-bottom: 5px solid var(--second-color);
    /* overflow-x: clip; */
}

nav{
    max-width: 1450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    transition: .6s ease;
    }

button.navbar {
    border: none;
    background: none;
    font-weight: 550;
    font-size: 18px;
    cursor: pointer;
}

button.navbar > img.logo{
    height: auto;
    width: 400px;
    margin-top: 6px;
}

button.navbar#contact {
    border: 3px solid var(--second-color);
    border-radius: 50px;
    padding: 13px;
    margin-left: 0px;
}

button.navbar#contact:hover{
    background-color: var(--second-color);
    color: var(--third-color);
    transition: .5s ease;
}

nav button:not(:last-child){
    margin-right: 20px;
}

nav  button:hover{
    color: var(--second-color);
}

nav  button.activePath {
    color: var(--second-color);
}

nav  button > i {
    color: var(--second-color);
    transition: .5s ease;
    margin-left: 5px;
    margin-right: 5px;
}

nav  button:hover > i {
    color: var(--first-color);
    transition: .5s ease;
}

nav  button.activePath > i {
    color: var(--first-color)
}

/* Dropdown Button */
.dropbtn {
  color: var(--first-color);
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 12px;
  z-index: 200;
}

/* Links inside the dropdown */
.dropContent a {
  color: black;
  padding: 12px 35px;
  text-decoration: none;
  font-weight: 600;
  display: block;
}

.dropContent a.active, .nestedHamburgerContent > a.activePath {
    color: var(--second-color);
}

/* Change color of dropdown links on hover */
.dropContent a:hover {
    border-radius: 12px;
    background-color: #ddd;
    color: var(--second-color);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropContent {
    display: block;
    z-index: 200;
}

/* Change the color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    color: var(--second-color);
}

/* Change the color of the icon when the dropdown content is shown */
.dropdown:hover i {
    color: var(--first-color);
}

div.mobileDropdown {
    display: none;
    position: relative;
    z-index: 200;
}

div.mobileDropdown > button.hamburger {
    border: none;
    background: none;
    color: var(--third-color);
    font-weight: 550;
    font-size: 18px;
    cursor: pointer;
}

div.mobileDropdown > button.hamburger > i {
    color: var(--second-color);
    margin-right: 20px;
}

div.hamburgerContent {
    position: absolute;
    top: 100%;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-right: 40px;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: var(--third-color);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    width: 50vw;
    z-index: 200;
}

div.hamburgerContent > button.navbar {
    padding: 10px;
    text-align: left;
    font-size: 25px;
    color: var(--first-color);
}

div.hamburgerContent > button#contact2 {
    border-top: 5px solid var(--second-color);
    color: var(--first-color);
    font-size: 28px;
    padding-top: 10px;
    margin-top: 5px;
    width: 100%;
}

div.nestedHamburgerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 15px;
    margin-bottom: 5px;
}

div.nestedHamburgerContent > a {
  color: var(--first-color);
  padding: 10px 10px;
  text-decoration: none;
  font-weight: 600;
  font-size: 25px;
}

div.nestedHamburgerContent > a.activePath {
    color: var(--second-color);
}

div.hamburgerContent > button.activePath {
    color: var(--second-color);
}

@media screen and (max-width: 1025px) {
    nav > button:not(:last-child){
        margin-right: 15px;
    }
    button.navbar > img.logo {
        width: 300px;
        padding-right: 10px;
    }
    button.navbar {
        font-size: 15px;
    }
    .dropContent a {
        padding-left: 15px;
        padding-right: 15px;
        color: var(--first-color);
    }

}

@media screen and (max-width: 850px) {
    
    div.mobileDropdown, nav > button.logoBtn {
        display: block;
        z-index: 200;
    }
    nav > button, nav > div.dropdown {
        display:none;
    }
}

@media screen and (max-width: 426px) {

    button.navbar > img.logo {
        width: 200px;
    }

    div.hamburgerContent {
        width: 70vw;
    }

    div.mobileDropdown > button.hamburger > i  {
        margin-right: 0px;
    }
}
</style>