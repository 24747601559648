<template>
  <section class="projectExp">
    <div class="projectExp-top">
      <div class="parallelogram1">
        <h1 class="projectTitle">DISCOVER THE CAPABILITIES OF OUR BRILLIANT ENGINEERS</h1>
      </div>
    </div>
    <div class="projectExp-body1">
      <!--ARC FLASH, CORPORATE, CORRECTIONAL -->
        <div class="arcFlash" @click="industryShowcase('ArcFlashHazardAnalysis')">
          <div class="img-container">
          <h3 id="arcFlash">Arc Flash Hazard Analysis</h3>
          </div>
          <img src="@/images/ProjectExperience/ArcFlash/Grange/grangeInsuranceArcFlash.jpg" alt="">
        </div>
        <div class="corporateOffices" @click="industryShowcase('CorporateOffices')">
          <div class="img-container">
          <h3 id="corporateOffices">Corporate Offices</h3>
          </div>
          <img src="..\images\ProjectExperience\CorporateOffice\Nationwide10West2sm.jpg" alt="">
        </div>
        <div class="correctional" @click="industryShowcase('Correctional')">
          <div class="img-container">
          <h3 id="correctional">Correctional</h3>
          </div>
          <img src="@/images/ProjectExperience/Correctional/London/london1.jpg" alt="">
        </div>
    </div>
    <div class="projectExp-body2">
      <!-- DATACENTER, HEALTHCARE, Higher Ed -->
        <div class="dataCenter" @click="industryShowcase('DataCenter')">
          <div class="img-container">
          <h3 id="dataCenter">Data Center</h3>
          </div>
          <img src="..\images\ProjectExperience\DataCenter\WorkersComp/WorkersComp1.jpg" alt="">
        </div>
        <div class="healthCare" @click="industryShowcase('HealthCare')">
          <div class="img-container">
          <h3 id="healthCare">Healthcare</h3>
          </div>
          <img src="..\images\ProjectExperience\HealthCare\OSUMorehouse.jpg" alt="">
        </div>
        <div class="higherEd" @click="industryShowcase('HigherEducation')">
          <div class="img-container">
          <h3 id="higherEd">Higher Education</h3>
          </div>
          <img src="..\images\ProjectExperience\HigherEducation\WithrowHall.jpeg" alt="">
        </div>
    </div>
    <div class="projectExp-body3">
      <!-- LABORATORIES, MIXED-USE, RECREATION -->
        <div class="laboratories" @click="industryShowcase('Laboratories')">
          <div class="img-container">
          <h3 id="laboratories">Laboratories</h3>
          </div>
          <img src="@/images/ProjectExperience/Laboratory/WSU/wsu2.jpg" alt="">
        </div>
        <div class="mixedUse" @click="industryShowcase('MixedUse')">
          <div class="img-container">
          <h3 id="mixedUse">Mixed-Use</h3>
          </div>
          <img src="@/images/ProjectExperience/MixedUse/BridgePark/bridgePark1.png" alt="">
        </div>
        <div class="recreation" @click="industryShowcase('Recreation')">
          <div class="img-container">
          <h3 id="recreation">Recreation</h3>
          </div>
          <img src="@/images/ProjectExperience/Recreation/6thPhotoHuntingtonParksm.jpg" alt="">
        </div>
    </div>
    <div class="mobileView">
      <div class="twoPerRow">
        <div class="arcFlash" @click="industryShowcase('ArcFlashHazardAnalysis')">
          <div class="img-container">
          <h3 id="arcFlash">Arc Flash Hazard Analysis</h3>
          </div>
          <img src="@/images/ProjectExperience/ArcFlash/Grange/grangeInsuranceArcFlash.jpg" alt="">
        </div>
        <div class="corporateOffices" @click="industryShowcase('CorporateOffices')">
          <div class="img-container">
          <h3 id="corporateOffices">Corporate Offices</h3>
          </div>
          <img src="..\images\ProjectExperience\CorporateOffice\Nationwide10West2sm.jpg" alt="">
        </div>
      </div>
      <div class="twoPerRow">
        <div class="correctional" @click="industryShowcase('Correctional')">
          <div class="img-container">
          <h3 id="correctional">Correctional</h3>
          </div>
          <img src="@/images/ProjectExperience/Correctional/London/london1.jpg" alt="">
        </div>
        <div class="dataCenter" @click="industryShowcase('DataCenter')">
          <div class="img-container">
          <h3 id="dataCenter">Data Center</h3>
          </div>
          <img src="..\images\ProjectExperience\DataCenter\WorkersComp/WorkersComp1.jpg" alt="">
        </div>
      </div>
      <div class="twoPerRow">
        <div class="healthCare" @click="industryShowcase('HealthCare')">
          <div class="img-container">
          <h3 id="healthCare">Healthcare</h3>
          </div>
          <img src="..\images\ProjectExperience\HealthCare\OSUMorehouse.jpg" alt="">
        </div>
        <div class="higherEd" @click="industryShowcase('HigherEducation')">
          <div class="img-container">
          <h3 id="higherEd">Higher Education</h3>
          </div>
          <img src="..\images\ProjectExperience\HigherEducation\WithrowHall.jpeg" alt="">
        </div>
      </div>
      <div class="twoPerRow">
        <div class="laboratories" @click="industryShowcase('Laboratories')">
          <div class="img-container">
          <h3 id="laboratories">Laboratories</h3>
          </div>
          <img src="@/images/ProjectExperience/Laboratory/WSU/wsu2.jpg" alt="">
        </div>
        <div class="mixedUse" @click="industryShowcase('MixedUse')">
          <div class="img-container">
          <h3 id="mixedUse">Mixed-Use</h3>
          </div>
          <img src="@/images/ProjectExperience/MixedUse/BridgePark/bridgePark1.png" alt="">
        </div>
      </div>
      <div class="twoPerRow">
        <div class="recreation" @click="industryShowcase('Recreation')">
          <div class="img-container">
          <h3 id="recreation">Recreation</h3>
          </div>
          <img src="@/images/ProjectExperience/Recreation/6thPhotoHuntingtonParksm.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    industryShowcase(industry) {
      this.$router.push({ name: "industry-showcase", params: {industry: industry}})
    },
  }
}
</script>

<style scoped>
section.projectExp {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0 auto;
  width: 100%;
  color: var(--third-color);
  padding-bottom: 20px;
  overflow-x: clip;
}

div.projectExp-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: rgba(80, 20, 4 , 1);
}

.projectTitle {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--third-color);
  margin: 0 auto;
  max-width: 800px;
  transform: skewX(20deg);
}

section.projectExp  h2 {
  font-family: 'Vollkorn', serif;
  padding-top: 20px;
  font-weight: 700;
  /* text-decoration: underline; */
}

section.projectExp > div.projectExp-body1, section.projectExp > div.projectExp-body2, section.projectExp > div.projectExp-body3  {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 1250px;
}

 section.projectExp img {
  width: 350px;
  height: 300px;
  border-radius: 40px;
}

section.projectExp div.img-container {
  position: absolute;
  top: 5.9%;
  background-color: rgba(73, 73, 73, 0.5);
  z-index: 2;
  font-size: 20px;
  height: 300px;
  width: 350px;
  border-radius: 40px;
  transition: all .5s ease;
}

div.arcFlash > div.img-container, div.dataCenter > div.img-container, div.laboratories > div.img-container {
  left: 2.65%;
}

div.corporateOffices > div.img-container, div.healthCare > div.img-container, div.mixedUse > div.img-container {
  left: 36.01%;
}

div.correctional > div.img-container, div.higherEd > div.img-container, div.recreation > div.img-container {
  left: 69.35%;
}

div.img-container > h3 {
  position: absolute;
  top: 85%;
  transition: all .8s ease;
}

section.projectExp div.img-container:hover {
  background-color: rgba(48, 48, 48, 0.7);
  cursor: pointer;
  /* -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); */
  transition: all .5s ease;
}

div.img-container:hover > h3 {
  top: 45%;
  transition: all .8s ease;
}

div.img-container > h3#arcFlash {
  left: 14%;
}

div.img-container > h3#corporateOffices {
  left: 27%;
}

div.img-container > h3#higherEd {
  left: 26%;
}

div.img-container > h3#correctional, div.img-container > h3#dataCenter, div.img-container > h3#healthCare, 
div.img-container > h3#laboratories, div.img-container > h3#mixedUse, div.img-container > h3#recreation {
  left: 35%;
}

div.mobileView {
  display: none;
}

@media screen and (max-width: 1025px) {
  section.projectExp > div.projectExp-body1, section.projectExp > div.projectExp-body2, section.projectExp > div.projectExp-body3  {
    display: none;
  }

  div.mobileView {
    display: flex;
    flex-direction: column;
  }

  div.mobileView > div.twoPerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 1000px;
  }
  div.mobileView > div.twoPerRow > div.arcFlash > div.img-container,
   div.mobileView > div.twoPerRow > div.correctional > div.img-container, 
   div.mobileView > div.twoPerRow > div.healthCare > div.img-container, 
   div.mobileView > div.twoPerRow > div.laboratories > div.img-container {
    left: 10%;
  }

  div.mobileView > div.twoPerRow > div.corporateOffices > div.img-container, 
   div.mobileView > div.twoPerRow > div.dataCenter > div.img-container, 
   div.mobileView > div.twoPerRow > div.higherEd > div.img-container, 
   div.mobileView > div.twoPerRow > div.mixedUse > div.img-container {
    left: 55.01%;
  }

  div.mobileView > div.twoPerRow > div.recreation > div.img-container {
    left: 32.5%;
  }
}

@media screen and (max-width: 850px) {
  section.projectExp > div.projectExp-body1, section.projectExp > div.projectExp-body2, section.projectExp > div.projectExp-body3  {
    display: flex;
  }

  div.mobileView {
    display: none;
  }

  .projectTitle {
    font-size: 33px;
  }
  
  section.projectExp > div.projectExp-body1, section.projectExp > div.projectExp-body2, section.projectExp > div.projectExp-body3 {
    display: flex;
    flex-direction: column;
    width: 700px;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  section.projectExp > div.projectExp-body1 > div, section.projectExp > div.projectExp-body2 > div, section.projectExp > div.projectExp-body3 > div {
    padding: 20px 20px;
  }

  section.projectExp div.img-container {
    left: 25%;
  }

  div.arcFlash > div.img-container, div.dataCenter > div.img-container, div.laboratories > div.img-container {
    top: 3.65%;
  }

  div.corporateOffices > div.img-container, div.healthCare > div.img-container, div.mixedUse > div.img-container {
    top: 35.6%;
  }

  div.correctional > div.img-container, div.higherEd > div.img-container, div.recreation > div.img-container {
    top: 67.8%;
  }

}

@media screen and (max-width: 376px) {
  .projectTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 321px) {

  .projectTitle {
    font-size: 24px;
  }
  section.projectExp img, section.projectExp div.img-container {
    width: 85vw;
    height: 80vw;
  }
  section.projectExp div.img-container {
    left: 30.5%;
  }

  div.arcFlash > div.img-container, div.dataCenter > div.img-container,  div.laboratories > div.img-container {
    top: 4.4%;
  }

  div.corporateOffices > div.img-container,  div.healthCare > div.img-container, div.mixedUse > div.img-container {
    top: 36.2%;
  }

  div.correctional > div.img-container, div.higherEd > div.img-container, div.recreation > div.img-container {
    top: 68%;
  }
  div.img-container > h3#arcFlash {
    top: 80%;
    left: 3.5%;
  }

  div.img-container > h3#corporateOffices {
    left: 17.5%;
  }

  div.img-container > h3#higherEd {
    left: 20%;
  }

  div.img-container > h3#correctional,
  div.img-container > h3#laboratories {
    left: 28%;
  }
  div.img-container > h3#dataCenter,
  div.img-container > h3#healthCare,
  div.img-container > h3#mixedUse,
  div.img-container > h3#recreation {
    left: 31%;
  }
}
</style>