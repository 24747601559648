<template>
  <div class="member">
    <!-- Conditionally render the image based on the imagePosition prop -->
    <template v-if="imagePosition === 'beforeTitle'">
      <img :src="imageSrc" :alt="imageAlt" class="memberImg">
    </template>
    <!-- No conditional rendering for mobile view -->
    <img :src="imageSrc" :alt="imageAlt" class="memberImg mobileImg">
    <div class="content">
      <h2><strong>{{ firstLetter }}</strong>{{name.slice(1)}}</h2>
      <h4>{{ title }}</h4>
      <p>{{ description }}</p>
    </div>
    <!-- Conditionally render the image after the paragraph -->
    <template v-if="imagePosition === 'afterParagraph'">
        <img :src="imageSrc" :alt="imageAlt" class="memberImg"/>
    </template>
  </div>
    
</template>

<script>
export default {
  props: {
    name: String,
    title: String,
    description: String,
    imageSrc: String,
    imagePosition: String,
    imageAlt: String,
  },
  computed: {
    firstLetter() {
       // Extract the first letter from the name prop
       return this.name.charAt(0);
     },
  },
};
</script>

<style scoped>
div.member {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  /* margin: 0 auto; */
}

div.member > img.memberImg {
  width: 350px;
  height: auto;
  padding: 10px;
  margin: 20px;
  border: 5px solid var(--second-color);
  border-radius: 10px;
}

div.member > img.mobileImg {
  display: none;
}

div.content {
  max-width: 40%;
}

div.member > div.content h2 {
  font-family: 'Vollkorn', serif;
  padding-left: 10px;
  font-weight: 700;
}

div.member > div.content h2 > strong {
  font-family: 'Vollkorn', serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--second-color);
}

div.member > div.content h4 {
  font-family: 'Vollkorn', serif;
  font-weight: 600;
  font-size: 20px;
  padding-left: 15px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: var(--fourth-color);
  border-bottom: 3px solid var(--second-color);
}

div.member > div.content p {
  margin-left: 20px;
  font-size: 18px;
  padding-bottom: 20px;
}

@media screen and (max-width: 850px) {
  div.member {
    max-width: 700px;
    margin: 15px;
  }
  
  div.member > img.memberImg {
    width: 300px;
    margin: 10px;
    padding: 8px;
  }

  div.member > div.content h2 > strong {
    font-size: 33px;
  }

  div.member > div.content h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 426px) {
  div.content {
    max-width: 90%;
  }

  div.member > div.content h2 {
    padding-top: 20px;
  }

  div.member > img.memberImg {
    display: none;
  }

  div.member > img.memberImg.mobileImg {
    display: block;
  }
}

@media screen and (max-width: 376px) {
  div.member > div.content h2, div.member > div.content h4 {
    padding-left: 5px;
  }

  div.member > div.content p { 
    margin-left: 15px;
  }
}

@media screen and (max-width: 321px) {
  div.member > img.memberImg.mobileImg {
    display: block;
    width: 270px;
  }
}
</style>