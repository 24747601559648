<template>
  <main>
    <nav-bar id="nav-bar"/>
    <router-view id="router-view"/>
    <footer-form id="footer-form" ref="footerForm"/>
  </main>
</template>

<script>
import NavBar from "../src/components/NavBar.vue";
import FooterForm from "../src/components/FooterForm.vue";

export default {
  components: { NavBar, FooterForm},
};

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
/* font-family: 'Vollkorn', serif; */

  /* Main Colors */
  --first-color: #000000ff;
  --second-color: #A32807ff;
  --third-color: #FFFFFFff;
  --fourth-color: #A5A5A5ff;

  /* Background Colors */
  /* --body-bg-color: #fefefe;
  --card-bg-color: #fff;
  --modal-bg-color: #fff; */
  --bg-transparent-color: rgba(0, 0, 0, 0.1);
  --transparent-color-01: rgba(0, 0, 0, 0.1);
  --transparent-color-02: rgba(182, 56, 52, 0.1);
  --line-color: #d7d7d7;

  /* Color Filter */
  --color-filter: invert(1);

  /* Box Shadow */
  --box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);

    /*=======Font size=======*/
    --small-font-size: 0.9rem;
    --normal-font-size: 1em;

  /*=======Scroll bar colors=======*/
    --scroll-bar-color: #c5cadf;
    --scroll-thumb-color: #70768a;
    --scroll-thumb-hover: #454f6b;

}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
}

body{
  color: var(--first-color);
  background: var(--third-color);
  margin: 2rem 0 0 0;
  transition: .5s ease;
  width: 100vw;
  overflow-x: hidden;
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.parallelogram1 {
  width: 60%;
  height: 300px;
  background-color: var(--second-color);
  position: relative;
  transform: skewX(-20deg);
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parallelogram2 {
  width: 60%;
  height: 300px;
  background-color: var(--fourth-color);
  position: relative;
  transform: skewX(20deg);
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parallelogram3 {
  width: 60%;
  height: 200px;
  background-color: var(--second-color);
  position: relative;
  transform: skewX(20deg);
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nav-bar {
  z-index: 100;
}

@media screen and (max-width: 850px) {
  .parallelogram1, .parallelogram2, .parallelogram3 {
    width: 75%;
  }

}
</style>
