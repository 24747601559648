<template>
  <footer>
    <section class="contactInfo">
      <div class="cta">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you!</p>
      </div>
      <div class="email">
        <i class="fa-solid fa-envelope fa-xl"></i>
        <a href="mailto:info@praterengr.com">info@praterengr.com</a>
      </div>
      <div class="phone">
        <i class="fa-solid fa-phone fa-xl"></i>
        <a href="tel:+1614-766-4896" target="_self">614.766.4896</a>
      </div>
      <div class="maps">
        <i class="fa-solid fa-location-dot fa-xl"></i>
        <a href="https://goo.gl/maps/ok75teT6eEKmiZnP6" target="_blank">6130 Wilcox Rd. <br> Dublin, OH 43016</a> <!-- CHECK IF THIS COMES UP AS 39.9900672, -83.0439424 AT HOME -->
      </div>
      <div class="hours">
        <i class="fa-solid fa-clock fa-xl"></i>
        <p>Mon - Thu: 7:30 AM - 5:30 PM<br>Fri: 7:30AM - 11:30 AM</p>
      </div>
    </section>
    <p><span>&copy;</span> 2023 Prater Engineering Associates, Inc.</p>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  methods: {
    
  },
}
</script>

<style scoped>
footer {
  padding-top: 5px;
  width: 100vw;
  overflow-x: clip;
}


footer > section.contactInfo {
  background-color: var(--second-color);
  color: var(--third-color);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 100px 50px 100px;
}

section.contactInfo > div.email, section.contactInfo > div.phone, section.contactInfo > div.maps, section.contactInfo > div.hours   {
  display: flex;
  flex-direction: row;
  align-items: center;
}

section.contactInfo > div.cta {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding-bottom: 20px; */
  /* padding-top: 10px; */
  padding-right: 100px;
  /* width: 400px; */
}

section.contactInfo > div.cta > h2 {
  border-bottom: 3px solid var(--third-color);
  font-size: 25px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

section.contactInfo > div.cta > p {
  padding-top: 5px;
  padding-bottom: 60px;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: var(--third-color);
}

i {
  margin-right: 15px;
}

footer > p {
  padding-left: 10px;
  font-size: 12px;
  color: var(--third-color);
  background-color: var(--second-color);
}

@media screen and (max-width: 10250px) {
  footer > section.contactInfo {
      display: grid;
      grid-template-areas: 
                          "contact email phone" 
                          "contact maps hours";
      grid-template-columns: 1fr 1fr 1fr;
      align-items: left;
      justify-content: left;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
      padding: 50px 50px;
  }

  section.contactInfo > div {
    text-align: left;
  }

  section.contactInfo > div.cta {
    grid-area: contact;
    padding-right: 50px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    /* padding: 10px 20px; */
  }

  section.contactInfo > div.email {
    grid-area: email;
    padding: 0px 20px 10px 40px;
  }

  section.contactInfo > div.phone {
    grid-area: phone;
    padding: 0px 0px 10px 20px;

  }

  section.contactInfo > div.maps {
    grid-area: maps;
    padding: 10px 20px 0px 40px;

  }

  section.contactInfo > div.hours {
    grid-area: hours;
    padding: 10px 0px 0px 20px;
  }

}
@media screen and (max-width: 850px) {

    section.contactInfo > div.cta {
      margin-top: 30px;
    }

    section.contactInfo > div.email {
      padding: 0px 20px 10px 0px;
    }

    section.contactInfo > div.phone {
      padding: 0px 0px 10px 20px;

    }

    section.contactInfo > div.maps {
      padding: 10px 20px 0px 0px;

    }

    section.contactInfo > div.hours {
      padding: 10px 0px 0px 20px;
    }
}

@media screen and (max-width: 426px) {
  footer > section.contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }

  section.contactInfo > div.cta {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }

  section.contactInfo > div.cta p {
    display: none;
  }

  section.contactInfo > div.email, section.contactInfo > div.phone, section.contactInfo > div.maps, section.contactInfo > div.hours {
    margin-top: 20px;
    margin-right: auto;
    padding: 10px;
  }
}
</style>